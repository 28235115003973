import { FC, ReactElement, useEffect, useMemo, useState } from 'react'

import { IBankDetails } from '@dltru/dfa-models'
import { BankAccountDetailsModal, Box, Button, Eye, LinkCell } from '@dltru/dfa-ui'

import { getBankDetailsByUserId } from '../Footer/Actions/BankAccountDetails/utils'

interface IDetails extends IBankDetails {
    paymentTargetText: string
    paymentTarget: ReactElement
}

interface IOwnerCellProps {
    ownerName?: string
    ownerId: string
    bankDetails?: boolean
}

export const OwnerCell: FC<IOwnerCellProps> = ({ ownerName, ownerId, bankDetails }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [details, setDetails] = useState<IDetails | undefined>()

    const link = useMemo(() => {
        return LinkCell(ownerName, `/clients/${ownerId}`)
    }, [ownerName, ownerId])

    useEffect(() => {
        const getBankData = async () => {
            const bankData = await getBankDetailsByUserId(ownerId)

            const paymentTargetText = `Выплата в рамках погашения выпуска ЦФА. НДС не облагается.`
            const paymentTarget = <p>{paymentTargetText}</p>

            if (bankData) {
                setDetails({ ...bankData, paymentTarget, paymentTargetText })
            }
        }

        if (bankDetails && ownerId) {
            getBankData()
        }
    }, [ownerId, bankDetails])

    return (
        <>
            {' '}
            <Box direction="row" justify="flex-start" align="center">
                {link}{' '}
                {bankDetails && (
                    <Button
                        style={{ marginLeft: 16 }}
                        icon={<Eye />}
                        borderRadius={8}
                        onClick={() => {
                            setIsModalVisible(true)
                        }}
                    >
                        Банковские реквизиты
                    </Button>
                )}
            </Box>
            {isModalVisible && details ? (
                <BankAccountDetailsModal
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    title="Банковские реквизиты Владельца выпуска ЦФА"
                    bodyText="Для погашения ЦФА Владельца совершите банковский перевод по указанным реквизитам."
                    paymentTargetText={details.paymentTargetText}
                    paymentTarget={details.paymentTarget}
                    recipient={details.recipient}
                    recipientInn={details.recipient_inn}
                    bankDetails={details || {}}
                />
            ) : null}
        </>
    )
}
