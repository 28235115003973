import { DfaStatusEnum, IDfaFront, IDfaOwner, RedemptionTypeDfa, SettlementsType } from '@dltru/dfa-models'
import {
    CaseBadge,
    ColumnsType,
    ConfirmCell,
    DetailsAmountCell,
    DetailsAmountHeader,
    PercentCell,
} from '@dltru/dfa-ui'

import { OwnerCell } from './OwnerCell'

const getTotalAmountDfa = (record: IDfaOwner) =>
    record.active_amount_dfa +
    record.blocked_amount_dfa +
    record.injunction_amount_dfa +
    record.encumbrance_amount_dfa +
    record.repayment_amount_dfa

const getTotalSum = (dfa: IDfaFront) => (value: number | undefined, record: IDfaOwner) => {
    const redeemPricePerDfa = dfa.redemption_type === RedemptionTypeDfa.fixed 
      ? dfa.redeem_price_per_dfa 
      : dfa.current_redeem_price_per_dfa

    if (redeemPricePerDfa === undefined) {
      return ''
    }

    if (value) {
        return (value * redeemPricePerDfa).toLocaleString()
    }
    
    return (getTotalAmountDfa(record) * redeemPricePerDfa).toLocaleString()
}

const CaseCell = (value: number) => <CaseBadge count={value.toLocaleString()} />
export const getGridConfig = (dfa: IDfaFront): ColumnsType<IDfaOwner> => {
    const columns: ColumnsType<IDfaOwner> = [
        {
            title: 'Текущий владелец',
            dataIndex: 'user_full_name',
            key: 'user_full_name',
            width: '63%',
            sorter: true,
            ellipsis: true,
            render: (value: string, record: IDfaOwner) => (
                <OwnerCell
                    ownerName={value || record.user_id}
                    ownerId={record.user_id}
                    bankDetails={
                        dfa.status === DfaStatusEnum.mature &&
                        dfa.issue_settlements_type === SettlementsType.direct
                    }
                />
            ),
        },
    ]
    if (dfa.status !== DfaStatusEnum.redeemed) {
        columns.push(
            {
                title: 'Доля, %',
                dataIndex: 'repurchase_percent',
                key: 'repurchase_percent',
                align: 'right',
                width: 170,
                sorter: true,
                ellipsis: true,
                render: (_, record: IDfaOwner) =>
                    PercentCell(getTotalAmountDfa(record) / dfa.total_supply_invested),
            },
            {
                title: 'Всего ЦФА, ед.',
                dataIndex: 'total',
                key: 'total',
                align: 'right',
                width: 170,
                sorter: true,
                render: (_, record: IDfaOwner) => CaseCell(getTotalAmountDfa(record)),
            },
            {
                title: DetailsAmountHeader,
                dataIndex: 'details_amount_dfa',
                key: 'details_amount_dfa',
                width: '40%',
                render: DetailsAmountCell,
            },
        )
    }
    if (
        dfa.repayment_settlements_type === SettlementsType.direct &&
        dfa.status === DfaStatusEnum.mature
    ) {
        columns.push(
            {
                title: 'Сумма погашения,  ₽',
                dataIndex: 'sum',
                key: 'sum',
                align: 'right',
                width: 170,
                render: getTotalSum(dfa),
            },
            {
                title: 'Согласие на погашение',
                dataIndex: 'is_investor_repayment_confirmed',
                key: 'is_investor_repayment_confirmed',
                width: 140,
                render: ConfirmCell('Согласен'),
            },
        )
        return columns
    }
    if (dfa.status === DfaStatusEnum.redeemed) {
        columns.push({
            title: 'Погашенные',
            dataIndex: 'repayment_amount_dfa',
            key: 'repayment_amount_dfa',
            width: '27%',
            sorter: true,
            ellipsis: true,
            render: (value: number, record: IDfaOwner) =>
                value + record.active_amount_dfa + (record.early_repayment_amount_dfa ?? 0),
        })
    }

    return columns
}
