import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DfaStatusEnum, DocumentsEnum, IDfaFront, SettlementsType } from '@dltru/dfa-models'
import { Table } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { getPage } from '@store/dfa/owners'
import { dfaOwnersListSelector } from '@store/dfa/owners/selectors'

import { DownloadBtn } from '@components/DownloadBtn/DownloadBtn'

import { getGridConfig } from './gridConfig'

export interface IOwnersTable {
    dfa: IDfaFront
}

export const OwnersTable: FC<IOwnersTable> = ({ dfa }) => {
    const reduxDispatch = useDispatch()
    const data = useSelector(dfaOwnersListSelector.selectData)
    const isLoading = useSelector(dfaOwnersListSelector.selectIsLoading)
    const withPayoffAgreements = dfa.repayment_settlements_type === SettlementsType.direct
    const userUuid = useSelector(authSelector.selectUserUid)

    useEffect(() => {
        if (dfa.id) {
            reduxDispatch(getPage({ dfaId: dfa.id, withPayoffAgreements }))
        }
    }, [dfa.id])

    const isShowDownloadButton =
        dfa.emitter_id === userUuid &&
        dfa.status === DfaStatusEnum.mature &&
        dfa.issue_settlements_type === SettlementsType.direct

    return (
        <Table
            isLoading={isLoading}
            columns={getGridConfig(dfa)}
            dataSource={data}
            scroll={{ y: 630 }}
            rowKey="user_id"
            headerRight={
                isShowDownloadButton ? (
                    <DownloadBtn
                        documentType={DocumentsEnum.dfa_investors}
                        params={{
                            asset_id: dfa.id,
                        }}
                        title="Банковские реквизиты Владельцев"
                    />
                ) : null
            }
        />
    )
}
