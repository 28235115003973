import { ISecondaryOrder, UnionOrderType } from '@dltru/dfa-models'
import { ColoredTag, ColumnsType, DateTimeCell } from '@dltru/dfa-ui'

import { SecondaryOrderActionButton } from '@components/SecondaryOrderActionButton'

const AmountCell = (_, record: UnionOrderType) => {
    if (record.amount_dfa !== undefined) {
        return record.amount_dfa // TODO заменить на ColoredTag когда будет цвет
    }
    return (
        <ColoredTag type={record.order_type || 'gray'}>
            {record.original_amount_dfa?.toLocaleString()}
        </ColoredTag>
    )
}
interface IGridConfigProps {
    actionBuy: (order: ISecondaryOrder) => void
    actionCancel: (order: ISecondaryOrder) => void
}
export const getGridConfig = (props: IGridConfigProps): ColumnsType<ISecondaryOrder> => [
    {
        title: 'Подача заявки',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        ellipsis: true,
        render: DateTimeCell,
    },
    {
        title: 'Кол-во, ед.',
        dataIndex: 'amount',
        key: 'amount',
        sorter: true,
        ellipsis: true,
        render: AmountCell,
    },
    {
        title: 'Цена, ₽',
        dataIndex: 'price_per_dfa',
        key: 'price_per_dfa',
        sorter: true,
        ellipsis: true,
        render: (value) => value?.toLocaleString(),
    },
    {
        title: 'Стоимость, ₽',
        dataIndex: 'total_price',
        key: 'total_price',
        sorter: true,
        ellipsis: true,
        render: (value) => value?.toLocaleString(),
    },
    /*    TODO позже вернуть
    {
        title: 'До снятия',
        dataIndex: 'expired_at',
        key: 'expired_at',
        ellipsis: true,
        render: RestDateCell(),
    },*/
    {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: 80,
        render: (_, record) => <SecondaryOrderActionButton order={record} {...props} />,
    },
]
