import { ITransferOrder, ITransferParams } from '@dltru/dfa-models'
import { Table } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { loadTransferOrdersDfa } from '@store/orders/transfer'
import { transferOrderSelector } from '@store/orders/transfer/selectors'

import { gridConfig } from '../../../../Offers/components/IndividualOffersTable/gridConfig'

interface IComponentProps {
    params: Partial<ITransferParams>
}
export const IndividualOffersTable: FC<IComponentProps> = ({ params }) => {
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()
    const data = useSelector(transferOrderSelector.selectData)
    const isLoading = useSelector(transferOrderSelector.selectIsLoading)

    useEffect(() => {
        reduxDispatch(loadTransferOrdersDfa(params))
    }, [])

    const onRow = (order: ITransferOrder) => ({
        onClick: () => {
            navigate(`/ownership-transfer-request/${order.global_id}`)
        },
    })
    return (
        <Table
            isLoading={isLoading}
            columns={gridConfig}
            dataSource={data}
            onRow={onRow}
            clickableRow
        />
    )
}
