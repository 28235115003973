import { ISecondaryOrder, OrderTypeEnum } from '@dltru/dfa-models'
import { Table } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '@store/index'
import { updateOrderDetails } from '@store/orders/details'
import { loadSecondaryDfasByAssetId } from '@store/secondaryDfa/list'

import { SecondaryOrderDetailsModal, SecondaryTradeBuyingModal } from '@components/Modals/Orders'
import { SecondaryRevokeModal } from '@components/Modals/Orders/Revoke'

import { getGridConfig } from './gridConfig'

export const OrdersDfaTable: FC<{ dfa?: string }> = ({ dfa }) => {
    const reduxDispatch = useDispatch()
    const { data, isLoading } = useSelector((store: AppState) => store.secondaryDfaList)
    const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false)
    const [isTradeModalVisible, setIsTradeModalVisible] = useState(false)
    const [isRevokeModalVisible, setIsRevokeModalVisible] = useState(false)
    const [currentOrder, setCurrentOrder] = useState<ISecondaryOrder>()

    useEffect(() => {
        if (dfa) {
            reduxDispatch(loadSecondaryDfasByAssetId(dfa))
        }
    }, [dfa])

    const actionBuy = (order: ISecondaryOrder) => {
        setCurrentOrder(order)
        setIsTradeModalVisible(true)
    }
    const actionCancel = (order: ISecondaryOrder) => {
        setCurrentOrder(order)
        setIsRevokeModalVisible(true)
    }
    const gridConfig = getGridConfig({ actionBuy, actionCancel })

    const onRow = (record: ISecondaryOrder) => ({
        onClick: () => {
            setCurrentOrder(record)
            setIsDetailsModalVisible(true)
            reduxDispatch(updateOrderDetails(record))
        },
    })

    return (
        <>
            <Table
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={data}
                onRow={onRow}
                clickableRow
            />
            <SecondaryOrderDetailsModal
                isModalVisible={isDetailsModalVisible}
                setIsModalVisible={setIsDetailsModalVisible}
                order={currentOrder}
            />
            <SecondaryTradeBuyingModal
                isModalVisible={isTradeModalVisible}
                setIsModalVisible={setIsTradeModalVisible}
                buying={currentOrder?.order_type === OrderTypeEnum.sell}
                selling={currentOrder?.order_type === OrderTypeEnum.buy}
                assetId={currentOrder?.asset_id}
                orderId={currentOrder?.id}
                accepted
                rest_amount_dfa={currentOrder?.rest_amount_dfa}
                price_per_dfa={currentOrder?.price_per_dfa}
                paymentCollectType={currentOrder?.payment_collect_type}
            />
            <SecondaryRevokeModal
                setIsModalVisible={setIsRevokeModalVisible}
                isModalVisible={isRevokeModalVisible}
                order={currentOrder}
            />
        </>
    )
}
