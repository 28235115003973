export const repaymentAgreementText = (
    <>
        <p>
            Настоящим Пользователь, в соответствии с пунктом 13.4 Правил, направляет указание на
            внесение в Реестр ЦФА записи о погашении ЦФА с Идентификационным номером выпуска ЦФА,
            указанным в графе «Тикер» формы «Согласие на погашение ЦФА». Пользователь подтверждает,
            что обязательства, удостоверенные соответствующими ЦФА, были исполнены Эмитентом в
            полном объеме. Указание на внесение в Реестр ЦФА записи о погашении ЦФА считается
            направленным с момента нажатия кнопки «Да, я даю согласие».
        </p>
        <p>
            Все термины, встречающиеся в настоящем тексте с заглавной буквы, применяются в значении,
            предусмотренном правилами информационной системы Общества с ограниченной
            ответственностью «Системы распределенного реестра».
        </p>
    </>
)
